import React from 'react';
import { Motion, spring, presets } from 'react-motion';

class TextSpinner extends React.Component {

    constructor(props) {
        super(props);
    }

    findPosition = (phrase) => {
        return this.props.phrases.indexOf(phrase);
    }

    getSanitizedPhrase = (phrase) => {
        return phrase.replace(/[^a-zA-Z0-9]/g, '');
    }

    render() {
        return (
            <Motion key={this.props.key} defaultStyle={{ top: 0 }} style={{ top: spring(this.findPosition(this.props.chosenPhrase), presets.wobbly) }}>
                {val => {
                    let style = {
                        position: 'absolute',
                        top: (val.top * 34) * -1
                    }
                    return (
                        <div className="bit">
                        <div style={style}>
                            {this.props.phrases.map(phrase => {
                                let sanitizedPhrase = this.getSanitizedPhrase(phrase);
                                let sanitizedChosenPhrase = this.getSanitizedPhrase(this.props.chosenPhrase);
                                let bitClass = 'bit-phrase';

                                if (sanitizedPhrase === sanitizedChosenPhrase) {
                                    bitClass += ' active '
                                }
                                return <div key={sanitizedPhrase} className={bitClass}>{phrase}</div>
                            })}
                        </div>
                    </div>
                    )
                }}
            </Motion>
        )
    }
}

export default TextSpinner;