import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PhraseSpinners from './components/PhraseSpinners';

class App extends React.Component {
    constructor() {
        super();
        this.state = { stdOut: 'Welcome to Pep Talker - the Pep Talk Generator.' }
    }

    setString(s) {
        this.setState({ stdOut: s });
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <PhraseSpinners str={this.state.stdOut} />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default App;